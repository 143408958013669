<template>
  <div
    id="okta-signin-container"
    class="login" />
</template>

<script>
import OktaSignIn from "@okta/okta-signin-widget"

export default {
  name: "Login",
  data: () => ({
    widget: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.widget = new OktaSignIn({
        el: '#okta-signin-container',
        baseUrl: process.env.VUE_APP_OKTA_URL,
        clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
        redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
        logo: "/mlb-logo.svg",
        i18n: {
          en: {
            'primaryauth.title': 'MLB Login',
            'primaryauth.submit': 'Login',
            'needhelp': 'Need help?',
          },
        },
        authParams: {
          pkce: false,
          responseType: ['id_token', 'token'],
          issuer: process.env.VUE_APP_OKTA_ISSUER,
          display: 'page',
          scopes: ['openid', 'profile', 'email'],
        },
      })

      this.widget.showSignInAndRedirect().catch(err => {
        console.error("unexpected error occurred during okta login", err)
      })
    })
  },
  destroyed() {
    if (this.widget) {
      this.widget.remove()
    }
  },
}
</script>

<style scoped>
@import "~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

.login >>> .auth-container {
  font-family: "Helvetica Neue", sans-serif !important;
  box-shadow: none !important;
  border-style: none !important;
  color: #999999 !important;
}

.login >>> .okta-form-title {
  font-size: 20px !important;
  font-weight: normal !important;
  color: #333333 !important;
}

.login >>> .auth-container .link:link {
  color: #999999 !important;
}

.login >>> .auth-content {
  padding-top: 10px !important;
}

.login >>> .auth-org-logo {
  max-height: 110px !important;
}

.login >>> .auth-container .button-primary {
  color: #ffffff !important;
  background: #0579ff !important;
  border-style: none !important;
}

.login >>> .auth-container .button-primary:hover,
.login >>> .auth-container .button-primary:focus,
.login >>> .auth-container .button-primary:active {
  background: #0062cc !important;
  border-style: none !important;
}
</style>
